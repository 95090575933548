
  
  span.input-group-addon {
    width: 8%;
    display: inline-block;
    line-height: 140%;
  }
  
  .input-group {
    display: inline-block;
    width:80%;
  }

  .input-group input {
    width:92%;
  }

  .id-column {
    width: 100px; /* Adjust the width as needed */
  }
  
  .legend {
    display: flex;
    align-items: center;
    margin-top: 20px;
    margin-bottom: 20px;
  }

  
  .legend-item {
    background-color: #f5f5f5; /* Adjust the background color as needed */
    padding: 5px 10px; /* Adjust the padding as needed */
    border-radius: 5px; /* Adjust the border radius as needed */
    margin-right: 10px; /* Adjust the margin as needed */
  }
  
  .legend img {
    width: 20px; /* Adjust the width as needed */
    height: auto;
    margin-right: 5px; /* Adjust the margin as needed */
  }
  
  .legend span {
    font-size: 14px; /* Adjust the font size as needed */
    color: #333; /* Adjust the color as needed */
  }