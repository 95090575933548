/* Ensure dropdown menu appears on top */
.select__menu {
    z-index: 9999; /* Adjust the z-index value as needed */
    color: black;
    background-color: black !important;
  }
  

  .input-group-addon.addon-button {
    border-left: 1px solid #ccc;
    color: white;
    background: #373d54;
    font-weight: normal; /* Default font weight */
  }

  .input-group-addon.addon-button:hover {
    cursor: pointer;

  }
