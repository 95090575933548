/* Home.css */
body {
    background-color: white; /* Set the background color to black */
  }
  
  .small-grey-text {
    font-size: 12px;
    color: #888; /* Light grey color */
  }
  
  .keywordSearch {
    max-width: 500px;
    width: 100%;
    display: inline-block;
  }